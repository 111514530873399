import React, { useState, createContext } from 'react'

type OverlayState = {
  activeOverlay: OverlayConstants
  hasMarketo: boolean
  marketoFormId: string
  setMarketoFormId: (id: string) => void
  toggleOverlay: (activeOverlay: OverlayConstants) => void
  updateMarketo: (value: boolean) => void
  closeOverlay: () => void
}

type OverlayChild = {
  children: React.ReactNode
}

export enum OverlayConstants {
  NONE = 'NONE',
  MARKETO = 'MARKETO',
  SEARCH_OVERLAY = 'SEARCH_OVERLAY',
  MENU_OVERLAY = 'MENU_OVERLAY',
  AUSTRALIA_OVERLAY = 'AUSTRALIA_OVERLAY'
}

export const OverlayContext = createContext({} as OverlayState)

export const OverlayProvider = ({ children }: OverlayChild) => {
  const [activeOverlay, setActiveOverlay] = useState<OverlayConstants>(
    OverlayConstants.NONE
  )
  const [marketoFormId, setMarketoForm] = useState('')
  const [hasMarketo, setHasMarketo] = useState(false)

  const toggleOverlay = (newActiveOverlay: OverlayConstants) => {
    if (activeOverlay !== newActiveOverlay) {
      setActiveOverlay(newActiveOverlay)
    } else {
      setActiveOverlay(OverlayConstants.NONE)
    }
  }

  const closeOverlay = () => {
    if (activeOverlay !== OverlayConstants.NONE) {
      setActiveOverlay(OverlayConstants.NONE)
    }
  }

  const setMarketoFormId = (id: string) => {
    setMarketoForm(id)
  }

  const updateMarketo = (value: boolean) => {
    setHasMarketo(value)
  }

  return (
    <OverlayContext.Provider
      value={{
        activeOverlay,
        marketoFormId,
        hasMarketo,
        closeOverlay,
        setMarketoFormId,
        toggleOverlay,
        updateMarketo
      }}
    >
      {children}
    </OverlayContext.Provider>
  )
}
